const dates = [
  {
    value: '0',
    label: 'Tutte le date del festival'
  },
  {
    value: '2610',
    label: 'Giovedì 26 ottobre'
  },
  {
    value: '2710',
    label: 'Venerdì 27 ottobre'
  },
  {
    value: '2810',
    label: 'Sabato 28 ottobre'
  },
  {
    value: '2910',
    label: 'Domenica 29 ottobre'
  },
  {
    value: '3010',
    label: 'Lunedì 30 ottobre'
  },
  {
    value: '3110',
    label: 'Martedì 31 ottobre'
  },
  {
    value: '0111',
    label: 'Mercoledì 1 novembre'
  },
  {
    value: '0211',
    label: 'Giovedì 2 novembre'
  },
  {
    value: '0311',
    label: 'Venerdì 3 novembre'
  },
  {
    value: '0411',
    label: 'Sabato 4 novembre'
  },
  {
    value: '0511',
    label: 'Domenica 5 novembre'
  }
];

export default dates;
