import React, { useEffect, useCallback, useState } from 'react';
import Slider from 'react-slick';
import axios from 'axios';
// import dayjs from 'dayjs';
import Seo from '../components/seo';
import Faq from '../components/faq';
import faqs from '../data/faqs.json';
import SliderEvents from '../components/slider-events';
import LaunchBox from '../components/launch-box';
import { useAuth } from '../context/auth';
import launchBoxes from '../utils/launch-boxes';
import { sortByOrarioCode } from '../utils/sort';
import EventoEvidenza from '../components/evento-evidenza';
// import FestivalGiornate from '../components/festival-giornate';
import useAllEvents from '../hooks/use-all-event';
// import festivalTempBg from '../images/bg-launch-registrati.jpg';

const settings = {
  dots: true,
  arrows: true,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 3000,
  speed: 1000,
  swipe: true,
  slidesToShow: 1,
  cssEase: 'cubic-bezier(0.165, 0.840, 0.440, 1.000)',
  responsive: [
    {
      breakpoint: 1440,
      settings: {
        arrows: false
      }
    }
  ]
};

const REACT_APP_EVENTS_URL = process.env.REACT_APP_EVENTS_URL;

const filterEvents = (events, serieId, date) => {
  if (!events) return [];
  const filteredEventsSerieId = serieId
    ? events.filter(
        (event) =>
          event.serie_evento &&
          event.serie_evento.filter((serie) => serie.id === serieId).length > 0
      )
    : events;
  const filteredEvents = date
    ? filteredEventsSerieId.filter((event) => event.orario_code && event.orario_code.includes(date))
    : filteredEventsSerieId;
  return filteredEvents;
};

// const filterEventsOrigin = (events, origine, date) => {
//   if (!events) return [];
//   const filteredEventsSerieId = origine
//     ? events.filter((event) => event.origine === origine)
//     : events;
//   const filteredEvents = date
//     ? filteredEventsSerieId.filter((event) => event.orario_code && event.orario_code.includes(date))
//     : filteredEventsSerieId;
//   return filteredEvents;
// };

const filterSeries = (serieList) => {
  const filteredSeries =
    serieList &&
    serieList
      .filter((serie) => serie.home && serie.id !== '1')
      .sort((serie1, serie2) => parseInt(serie1.home) - parseInt(serie2.home));
  return filteredSeries;
};

const sortEvents = (events) => events.sort(sortByOrarioCode);

// const placeholderEvent = {
//   titolo: 'Festival della Scienza 2022',
//   sottotitolo: 'Conferenze on-demand',
//   customCta: {
//     link: '/festival-2022/',
//     label: 'Programma degli eventi'
//   }
// };

const Home = () => {
  const { user } = useAuth();
  const [serieList, setSerieList] = useState();

  const { events, loading } = useAllEvents(sortEvents);

  const loadSerieList = useCallback(async () => {
    const { data } = await axios.get(`${REACT_APP_EVENTS_URL}/serieList.json`);
    setSerieList(data.serie);
  }, []);

  // const oggiInOnda = useMemo(
  //   () => filterEventsOrigin(events, '2022', dayjs().format('DDMM')),
  //   [events]
  // );

  useEffect(() => {
    loadSerieList();
  }, [loadSerieList]);

  return (
    <>
      <Seo title="Festival della Scienza online" />
      {!user &&
        launchBoxes.length &&
        (launchBoxes.length > 1 ? (
          <Slider {...settings} className="slick-slider slider-launch-box">
            {launchBoxes.map((box, index) => (
              <LaunchBox box={box} key={index} />
            ))}
          </Slider>
        ) : (
          <LaunchBox box={launchBoxes[0]} />
        ))}
      {!loading ? (
        <>
          {
            user &&
              (serieList && serieList.some((serie) => serie.id === '1' && serie.home) ? (
                <div className="mask">
                  <div className="mask__bg"></div>
                  <Slider {...settings} className="slick-slider slider-launch-box">
                    {filterEvents(events, '1').map((event) => (
                      <EventoEvidenza event={event} key={event.id} />
                    ))}
                  </Slider>
                </div>
              ) : null) //<EventoEvidenza event={placeholderEvent} />
          }
          {/* {oggiInOnda.length > 0 && (
            <section className="section section--slider">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <h2 className="mt-3 mb-4">Oggi in onda</h2>
                    <SliderEvents events={oggiInOnda} />
                  </div>
                </div>
              </div>
            </section>
          )} */}
          {/* <section className="section">
            <FestivalGiornate />
          </section> */}
          {!user && (
            <section className="section section--bg-gray">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <h2 className="mt-3 mb-4">Domande frequenti</h2>
                    <div className="section__content">
                      {faqs.faqs.map((faq, i) => (
                        <Faq title={faq.question} className="mb-3" key={i}>
                          <div dangerouslySetInnerHTML={{ __html: faq.answer }}></div>
                        </Faq>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
          {user &&
            serieList &&
            filterSeries(serieList) &&
            filterSeries(serieList).map((serie) => {
              return (
                filterEvents(events, serie.id).length > 0 && (
                  <section className="section section--slider" key={serie.id}>
                    <div className="container">
                      <div className="row">
                        <div className="col-12">
                          <h2 className="mt-3 mb-4">{serie.titolo}</h2>
                          <SliderEvents events={filterEvents(events, serie.id)} />
                        </div>
                      </div>
                    </div>
                  </section>
                )
              );
            })}
        </>
      ) : (
        <div className="container">
          <div className="row">
            <div className="col-12 text-center py-5">
              <span
                className="spinner-border spinner-border-sm mr-1"
                style={{ width: '3rem', height: '3rem' }}
                role="status"
                aria-hidden="true"
              ></span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
