import React, { useEffect, useCallback, useState } from 'react';
import { Redirect } from '@reach/router';
import axios from 'axios';
import Breadcrumbs from '../components/breadcrumbs';
import Link from '../components/link';
import { useAuth } from '../context/auth';
import { formatIntroduzione, formatCollaboratori } from '../utils/helpers';
import defaultImage from '../images/conferenze-spettacolo.png';
import Seo from '../components/seo';
import useApi from '../context/useApi';
import ToggleSwitch from '../components/toggle-switch';

const REACT_APP_EVENTS_URL = process.env.REACT_APP_EVENTS_URL;
const breadcrumbs = [{ label: 'Home', link: '/' }];

const EventDetail = ({ eventId, location }) => {
  const { user, userData } = useAuth();
  const { requestStreaming } = useApi();
  const [event, setEvent] = useState();

  const [error, setError] = useState(false);
  const loadEvent = useCallback(async (eventId) => {
    try {
      const { data } = await axios.get(`${REACT_APP_EVENTS_URL}/events/${eventId}.json`);
      // console.log('event: ', data);
      setEvent(data);
    } catch (err) {
      console.error('Error loading event: ', err);
      setError(true);
    }
  }, []);

  useEffect(() => {
    if (eventId) {
      loadEvent(eventId);
    }
  }, [loadEvent, eventId]);

  const [showOrgUrl, setShowOrgUrl] = useState(false);

  const [urlStreaming, setUrlStreaming] = useState(null);
  const [urlOrgStreaming, setUrlOrgStreaming] = useState(null);
  const loadStreaming = useCallback(async () => {
    try {
      const {
        data: { url, urlTraduzioni }
      } = await requestStreaming(eventId);
      setUrlStreaming(url);
      setUrlOrgStreaming(urlTraduzioni);
    } catch (err) {
      console.log('Errore: ', err);
    }
  }, [eventId, requestStreaming]);

  useEffect(() => {
    if (event && userData && (userData.abbonamento || event.free)) {
      loadStreaming();
    }
  }, [event, userData, loadStreaming]);

  // console.log(location.pathname.split("/"))

  if (!eventId) {
    return <Redirect to="/" noThrow />;
  }

  if (error) {
    return (
      <div className="event-detail">
        <Seo title="404: Evento non trovato" robots="noindex,nofollow" />
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 className="text-center py-5">Evento non trovato</h3>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!user) {
    return <Redirect to="/login" noThrow />;
  }

  return (
    <div className="event-detail">
      <div className="container">
        {event ? (
          <>
            <Seo
              title={`${event.titolo} - Festival della Scienza online`}
              description={event.sottotitolo}
            />
            <div className="row">
              <div className="col-12">
                <Breadcrumbs
                  breadcrumbs={breadcrumbs.concat([
                    event.origine === '2023'
                      ? { label: 'Festival 2023', link: '/festival-2023' }
                      : { link: '/tutti-video', label: 'Tutti i video' },
                    // { link: '/tutti-video', label: 'Tutti i video' },
                    { label: event.titolo }
                  ])}
                />
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-lg-9">
                <h1>{event.titolo}</h1>
                {event.sottotitolo ? <h2>{event.sottotitolo}</h2> : null}
                <h3 className="mt-3">{formatIntroduzione(event)}</h3>
                {urlOrgStreaming && (
                  <div className="d-flex">
                    <div className="labelled-switch ml-auto">
                      <ToggleSwitch checked={showOrgUrl} setChecked={setShowOrgUrl} />
                      <label onClick={() => setShowOrgUrl((val) => !val)}>Lingua originale</label>
                    </div>
                  </div>
                )}
                {userData && (userData.abbonamento || event.free) && urlStreaming ? (
                  <div className="event-detail__streaming mt-4">
                    <>
                      {eventId === '000' ? (
                        <iframe
                          src="https://www.youtube.com/embed/BRa6D_HMoM4"
                          title="Streaming"
                          width="100%"
                          height="100%"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen={true}
                        ></iframe>
                      ) : (
                        <iframe
                          src={showOrgUrl ? urlOrgStreaming : urlStreaming}
                          title="Streaming"
                          width="100%"
                          height="100%"
                          frameBorder="0"
                          allow="autoplay; fullscreen"
                          allowFullScreen={true}
                        ></iframe>
                      )}
                    </>
                  </div>
                ) : null}
                {userData &&
                (userData.abbonamento || event.free) &&
                event.eventi_organizzazione.url_widget_qa ? (
                  <div className="event-detail__questions mt-4">
                    {event.eventi_organizzazione.url_widget_qa && (
                      <iframe
                        src={event.eventi_organizzazione.url_widget_qa}
                        height="100%"
                        width="100%"
                        frameBorder="0"
                        style={{ minHeight: '560px' }}
                        title="Slido"
                      ></iframe>
                    )}
                  </div>
                ) : null}
                {userData && (userData.abbonamento || event.free) && eventId === '000' ? (
                  <div>
                    <h4 className="mt-2">
                      A fronte di un problema tecnico la versione integrale del video sarà
                      disponibile on demand
                    </h4>
                  </div>
                ) : null}
                <div className="clearfix">
                  {userData && (userData.abbonamento || event.free) && urlStreaming ? (
                    <div className="mt-4">
                      <div
                        className="event-detail__description"
                        dangerouslySetInnerHTML={{ __html: event.descrizione }}
                      ></div>
                    </div>
                  ) : (
                    <div className="mt-4">
                      <div className="event-detail__img">
                        <img
                          src={
                            event.immagini && event.immagini[0]
                              ? event.immagini[0].url
                              : defaultImage
                          }
                          alt=""
                        />
                      </div>
                      <div
                        className="event-detail__description"
                        dangerouslySetInnerHTML={{ __html: event.descrizione }}
                      ></div>
                    </div>
                  )}
                </div>
                {event.note ? (
                  <div className="mt-3" dangerouslySetInnerHTML={{ __html: event.note }}></div>
                ) : null}
                {event.collaboratori ? (
                  <div className="mt-4">
                    <h3>In collaborazione con </h3>
                    {formatCollaboratori(event.collaboratori)}
                  </div>
                ) : null}
                {event.curatori ? (
                  <div className="mt-4">
                    <h3>A cura di </h3>
                    {formatCollaboratori(event.curatori)}
                  </div>
                ) : null}
                {event.relatori &&
                event.relatori.filter((relatore) => relatore.biografia).length ? (
                  <div className="mt-4">
                    <h3>
                      {event.tipologia_spettacolo || !event.tipologia_conferenza
                        ? 'Protagonisti'
                        : 'Relatori'}
                    </h3>
                    {event.relatori
                      .filter((relatore) => relatore.biografia)
                      .map((relatore) => (
                        <div
                          className="mb-3"
                          dangerouslySetInnerHTML={{ __html: relatore.biografia }}
                          key={relatore.idrelatore}
                        ></div>
                      ))}
                  </div>
                ) : null}
              </div>
              {event && (
                <div className="col-lg-3">
                  {event && !event.free ? (
                    <>
                      {user && !event.free ? (
                        userData && userData.abbonamento ? (
                          userData.expireDate ? (
                            <div>
                              <div className="mb-3">Il tuo abbonamento è scaduto</div>
                              <Link to="/accesso/" className="btn mb-5">
                                Rinnova abbonamento
                              </Link>
                            </div>
                          ) : null
                        ) : (
                          <Link to="/accesso/" className="btn mb-5">
                            Acquista abbonamento
                          </Link>
                        )
                      ) : (
                        <Link to="/accesso/" className="btn mb-5">
                          Acquista abbonamento
                        </Link>
                      )}
                    </>
                  ) : (
                    <>
                      {user ? null : (
                        <Link to="/registrati/" className="btn mb-5">
                          Registrati
                        </Link>
                      )}
                    </>
                  )}
                  {event.loghi && event.loghi.length && typeof event.loghi === 'object' ? (
                    <div className="option-box mb-4">
                      <div>
                        {event.loghi.map((logo) => (
                          <a
                            href={logo.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="d-block mb-4"
                            title={logo.nome}
                            aria-label={logo.nome}
                            key={logo.url}
                          >
                            <img
                              src={
                                event.origine === '2021' && !logo.url.includes('/2021/')
                                  ? `https://d1nbhjm614x963.cloudfront.net${logo.url}`
                                  : logo.url
                              }
                              alt={logo.nome_breve}
                              className="option-box__logo"
                            />
                          </a>
                        ))}
                      </div>
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          </>
        ) : (
          <div className="row">
            <div className="col-12 text-center">
              <div className="d-inline-flex align-items-center">
                <span className="spinner-border mr-1" role="status" aria-hidden="true"></span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EventDetail;
