import React, { useLayoutEffect, useEffect } from 'react';
import { Auth } from '@aws-amplify/auth';
import { Router, Redirect } from '@reach/router';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
import { AuthContext, useAuthData } from './context/auth';

import Layout from './components/layout';
import PrivateRoute from './components/private-route';
import RouteChangeHandler from './components/route-change-handler';

import CookieBot from './components/cookiebot';
import Festival from './routes/festival';
import TuttiVideo from './routes/tutti-video';
import Login from './routes/login';
import Registrati from './routes/registrati';
import ConfermaRegistrazione from './routes/conferma-registrazione';
import EventDetail from './routes/event-detail';
import Abbonamento from './routes/abbonamento';
import CodiceCoupon from './routes/codice-coupon';
import MieiDati from './routes/i-miei-dati';
import PasswordDimenticata from './routes/password-dimenticata';
import Fatto from './routes/fatto';
import Page404 from './routes/404';
import Informazioni from './routes/informazioni';
import AcquistoCompletato from './routes/acquisto-completato';
import AcquistoFallito from './routes/acquisto-fallito';
import CookiePolicy from './routes/cookie-policy';
import Home from './routes/home';
import ConfermaIscrizioneStream from './routes/conferma-iscrizione-stream';
import AvvenutaIscrizioneStream from './routes/avvenuta-iscrizione-stream';
import ErroreStream from './routes/errore-stream';
import SyllabusForm from './routes/syllabus-form';
import ConfermaAcquisto from './routes/conferma-acquisto';
import LocalEvents from './routes/local-events';

const instance = createInstance({
  urlBase: 'https://www.festivalscienza.online',
  siteId: '3',
  trackerUrl: 'https://festivalscienza.matomo.cloud/matomo.php',
  srcUrl: 'https://cdn.matomo.cloud/festivalscienza.matomo.cloud/matomo.js',
  disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  heartBeat: {
    // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 15 // optional, default value: `15
  },
  linkTracking: true // optional, default value: true
});

const App = () => {
  useLayoutEffect(() => {
    Auth.configure({
      region: process.env.REACT_APP_REGION,
      userPoolId: process.env.REACT_APP_USERPOOL_ID,
      userPoolWebClientId: process.env.REACT_APP_USERPOOL_WEBCLIENT_ID
    });
  }, []);

  const authData = useAuthData();

  useEffect(() => {
    // Rimuovi iframe abusivi se l'utente non è loggato o con abbonamento
    if (!authData.userData) {
      const interval = setInterval(() => {
        const iframes = document.getElementsByTagName('iframe');
        const iframeList = [...iframes];
        iframeList.forEach((iframe) => {
          if (iframe.src.includes('vmeo')) {
            iframe.remove();
          }
        });
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [authData]);

  return (
    <MatomoProvider value={instance}>
      <AuthContext.Provider value={authData}>
        <Layout>
          <Router>
            <PrivateRoute path="/codice-coupon" component={CodiceCoupon} />
            <PrivateRoute path="/i-miei-dati" component={MieiDati} />
            <Login path="/login" />
            <PasswordDimenticata path="/password-dimenticata" />
            <Registrati path="/registrati" />
            <Fatto path="/registrati/fatto" />
            <ConfermaRegistrazione path="/conferma-registrazione" />
            <Festival path="/festival-2023" />
            <LocalEvents path="/festival/:year/:localId" />
            <EventDetail path="/events/:eventId" />
            <Informazioni path="/faq" />
            <Abbonamento path="/accesso" />
            <AcquistoCompletato path="/acquisto-completato" />
            <AcquistoFallito path="/acquisto-fallito" />
            <Redirect from="events" to="/tutti-video" noThrow />
            <CookiePolicy path="/cookie-policy" />
            <TuttiVideo path="/tutti-video" />
            <SyllabusForm path="/stream-creator/scarica-programma" />
            <ConfermaIscrizioneStream path="/stream-creator/conferma-iscrizione" />
            <AvvenutaIscrizioneStream path="/stream-creator/avvenuta-iscrizione" />
            <ErroreStream path="/stream-creator/errore" />
            <ConfermaAcquisto path="/stream-creator/thank-you" />
            <Home path="/" />
            <Page404 path="*" default />
          </Router>
          <RouteChangeHandler />
        </Layout>
      </AuthContext.Provider>
      <CookieBot />
    </MatomoProvider>
  );
};

export default App;
